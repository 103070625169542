import React, {useEffect} from "react";
import Meta from "./../components/Meta";
import HeroSection from "./../components/HeroSection";
import FeaturesSection from "./../components/FeaturesSection";
import ClientsSection from "./../components/ClientsSection";
import NewsletterSection from "./../components/NewsletterSection";
import CtaSection from "./../components/CtaSection";
import seattle from "../assets/seattle.JPG";


async function loadFonts() {
    const font = new FontFace("Belleza-Regular", "url(Belleza-Regular.ttf)");
    // wait for font to be loaded
    await font.load();
    // add font to document
    document.fonts.add(font);
    // enable font with CSS class
    document.body.classList.add('fonts-loaded');
}
function IndexPage(props) {
    useEffect(() => {
        // call the function
  loadFonts()
    // make sure to catch any error
    .catch(console.error);
}, [])

  return (
    <>
      <Meta />
      <HeroSection
        bg="primary"
        textColor="light"
        size="lg"
        bgImage={seattle}
        bgImageOpacity={0.3}
        title="Soldarite"
        subtitle="Seattle Technical Recruiting, Refactored."
        buttonText="Let's Go!"
        buttonColor="light"
        buttonPath="/contact"
      />
      <FeaturesSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Features"
        subtitle="All the features you need to move faster"
      />
      <ClientsSection
        bg="light"
        textColor="dark"
        size="sm"
        bgImage=""
        bgImageOpacity={1}
        title="You're in good company: Soldarite's roster includes people with technical backgrounds from"
        subtitle=""
      />
      {/*<TestimonialsSection*/}
      {/*  bg="white"*/}
      {/*  textColor="dark"*/}
      {/*  size="md"*/}
      {/*  bgImage=""*/}
      {/*  bgImageOpacity={1}*/}
      {/*  title="Here's what people are saying"*/}
      {/*  subtitle=""*/}
      {/*/>*/}
      <NewsletterSection
        bg="light"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Stay in the know"
        subtitle="Receive our latest articles and feature updates"
        buttonText="Subscribe"
        buttonColor="primary"
        inputPlaceholder="Enter your email"
        subscribedMessage="You are now subscribed!"
      />
      <CtaSection
        bg="primary"
        textColor="light"
        size="sm"
        bgImage=""
        bgImageOpacity={1}
        title="Ready to get started?"
        subtitle=""
        buttonText="Get Started"
        buttonColor="light"
        buttonPath="/pricing"
      />
    </>
  );
}

export default IndexPage;
