import React from "react";
import "./SectionHeader.scss";
import styled from 'styled-components';

const StyledTitle = styled.h1`
    font-family:  "Belleza-Regular", Helvetica, sans-serif;
    text-shadow: 2px 2px 1px #1e1a1a;
    font-weight: bold;
`;

const StyledSubtitle = styled.p`
    font-family:  "Courier New", Helvetica, sans-serif;
    text-shadow: 2px 2px 1px #1e1a1a;
    font-weight: 900;
    font-size: 20px;
`;

const StyleWrapper = styled.div`
  h1 {
     font-family:  "Belleza-Regular", Helvetica, sans-serif;
    text-shadow: 2px 2px 1px #1e1a1a;
    font-weight: bold;
  }
  
  p {
    font-family:  "Courier New", Helvetica, sans-serif;
    text-shadow: 2px 2px 1px #1e1a1a;
    font-weight: 900;
    font-size: 20px;
  }
  
  @media (max-width: 768px) {
    p {
      font-size: 16px;
    }
  }
`
function HeroSectionHeader(props) {
  // Render nothing if no title and no subtitle
  if (!props.title && !props.subtitle) {
    return null;
  }

  return (
    <header
      className={
        "SectionHeader" + (props.className ? ` ${props.className}` : "")
      }
    >
      {props.title && (
       <StyleWrapper> <h1
          className={
            "font-weight-bold" +
            (props.subtitle && props.spaced ? " mb-4" : "") +
            (!props.subtitle ? " mb-0" : "") +
            (props.size ? ` h${props.size}` : "")
          }
        >
          {props.title}
       </h1> </StyleWrapper>
      )}
      {props.subtitle && (

          <StyleWrapper><p className="SectionHeader__subtitle mb-0">{props.subtitle}</p> </StyleWrapper>
      )}
    </header>
  );
}

export default HeroSectionHeader;
